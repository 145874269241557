import React from "react";
import { useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import "./style.css";

const DateRangeInput = (props: any) => {
  const {
    startDate,
    startDateId,
    endDate,
    endDateId,
    onDatesChange,
    isOutsideRange = () => {},
    onFocusChange = () => {},
    showClearDates = false,
    disabled = false,
  } = props;
  const { t, i18n } = useTranslation("general");
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
    onFocusChange(focusedInput);
  };

  return (
    <Box dir="ltr" width={"fit-content"}>
      <DateRangePicker
        small
        startDate={startDate} // moment.Moment | null
        startDateId={startDateId} // moment.Moment | null
        endDate={endDate} // momentPropTypes.momentObj or null
        endDateId={endDateId} // string
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        isOutsideRange={isOutsideRange}
        showDefaultInputIcon
        showClearDates={showClearDates}
        disabled={disabled}
        minimumNights={0}
        isRTL={i18n.language === "ar"}
        startDatePlaceholderText={t("startDate")}
        endDatePlaceholderText={t("endDate")}
        // phrases={{closeDatePicker: '关闭',clearDates: '清除日期'}}
      />
    </Box>
  );
};

export default DateRangeInput;
