import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  page?: number;
}

export const useGetCustomers = (params: IParams) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-admin-customers", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/admin/users`, { params });
    },
  });

export const useUpdateCustomerMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { id: string; dataToSend: FormData }
    // { id: string; name: string; email: string; phone: string; reason: string }
  >({
    mutationFn: async (data) => {
      const { id, dataToSend } = data;
      return apiClient.post(`/api/admin/users/${id}?_method=PUT`, dataToSend);
    },
  });

export const useGeCustomerById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-admin-customer-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/admin/users/${id}`);
    },
    gcTime: 0,
    enabled,
  });

interface IBranchAPIBody {
  id?: number;
  name?: string;
  city_id?: number;
  vat?: number;
  status?: boolean;
  has_items?: boolean;
}

export const useUpdateBranchMutation = () =>
  useMutation<any, APIErrorResponse, IBranchAPIBody>({
    mutationFn: async (data) => {
      const { id, ...restData } = data;
      return apiClient.put(`/api/admin/branches/${id}`, restData);
    },
  });
