import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  start_date: string;
  end_date: string;
}

export const useSendGeneralDataReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["send-general-data-report", params.start_date, params.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/admin/general-report`, {
        params: params,
      });
    },
    enabled: false,
  });
