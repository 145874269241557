export const convertObjectToFormData = (object: any) => {
  let formData = new FormData();
  for (let key in object) {
    formData.append(key, object[key]);
  }

  return formData;
};

export const formatBytes = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export const formatLongNumbers = (value: number): string => {
  if (value === undefined) {
    return "";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "k";
  } else {
    return value.toFixed(0);
  }
};

export const downloadFileFromUrl = (url: string, filename: string): void => {
  // Create an anchor element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); // Specifies the download filename
  link.target = "_blank"; // Opens in a new window or tab if it's an image
  document.body.appendChild(link); // Append the link to the document body

  // Programmatically trigger the download by clicking the link
  link.click();

  // Cleanup: Remove the link from the DOM
  link.parentNode?.removeChild(link);
};

export const formatNumber = (input: number) => {
  // Convert input to a number
  const num = Number(input);
  
  // If it's not a valid number, return the input as is
  if (isNaN(num)) {
    return input;
  }

  // Otherwise, format the number with commas
  return new Intl.NumberFormat().format(num);
};

export const downloadFile = (file: any, type: string, name: string) => {
  // Convert file data to Blob
  const blob = new Blob([file], { type: `${type};charset=utf-8;` });
  // Create a link element
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();
  // Clean up
  document.body.removeChild(link);
};
