import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Chip, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableHeadText from "./table-head-text";
import { useUpdateBranchMutation } from "src/api/customers";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

const BranchesTable = ({
  list,
  isLoading,
  refetch,
}: {
  list: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const { t } = useTranslation("customers");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("branchName"),
    generalT("vat"),
    generalT("branchLocation"),
    generalT("activeInactive"),
    t("itemsFeature"),
  ];
  const emptyDataArr = list.length === 0;
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { mutate, status, error } = useUpdateBranchMutation();

  //Update branch success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <Typography fontWeight={600} mb={1}>
        {t("theBranches")}
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {list?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>%{row.vat}</TableCell>
                  <TableCell>{row.city.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={generalT(row.status ? "active" : "inactive")}
                      color={row.status ? "success" : "warning"}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <TableSwitch /> */}
                    <Switch
                      defaultChecked={row.has_items}
                      onChange={(e) => {
                        mutate({ id: row.id, has_items: e.target.checked });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default BranchesTable;
