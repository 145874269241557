import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "src/assets/svg-icons/admin/right-arrow.svg";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import jazarCustomersIcon from "src/assets/svg-icons/admin/jazar.svg";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import InfoBox from "src/shared/components/info-box";
import {
  useGeCustomerById,
  useUpdateCustomerMutation,
} from "src/api/customers";
import FilePondUploader from "src/shared/components/file-pond-uploader";

const schema = yup.object().shape({
  group_name: yup.string().required("Group Name is required"),
  name: yup.string().required("required"),
  email: yup.string().required("required"),
  phone: yup.string().required("required"),
  reason: yup.string().required("required"),
});

export interface FormFields extends yup.InferType<typeof schema> {}

const EditCustomer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("customers");
  const { t: generalT } = useTranslation("general");
  const [files, setFiles] = useState<any>([]);

  // APIS
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateCustomerMutation();
  const { data, refetch } = useGeCustomerById({
    enabled: !!params.id,
    id: params.id as string,
  });

  // react hook form

  const { control, handleSubmit, formState, reset } = useForm<FormFields>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const navigateToRolesManagement = () => {
    navigate("/roles-management");
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log({ data });
    const { group_name, ...rest } = data;
    const dataToSend: any = rest;

    const formData = new FormData();
    // append data
    for (const key in dataToSend) {
      formData.append(key, dataToSend[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`file`, file);
      });
    }
    mutateUpdate({
      id: params.id as string,
      dataToSend: formData,
    });
  };

  useEffect(() => {
    if (data) {
      reset({
        name: data.data.name,
        group_name: data.data.company?.name,
        phone: data.data.phone || "",
        email: data.data.email || "",
      });
    }
  }, [data]);

  //Update ..... success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      // refetch();
      navigate("/customers");
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToRolesManagement}
          sx={{ cursor: "pointer" }}
        >
          {t("adminSystem")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToRolesManagement}
          sx={{ cursor: "pointer" }}
        >
          {t("jazrCostumer")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            id
          </Typography>
        </Box>
      </Stack>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        <Avatar sx={{ bgcolor: "#D0D5DD", mb: 1.5 }}>
          <img src={jazarCustomersIcon} width={"32px"} alt="" />
        </Avatar>
        <Typography fontSize={"32px"} fontWeight={400} lineHeight={"38.4px"}>
          Ahmed Group ID-909090
        </Typography>
      </Box>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5} px={"180px"} alignContent={"center"}>
          <Stack direction={"row"} spacing={1.5}>
            {/* group_name */}
            <Controller
              name="group_name"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.group_name}
                  helperText={formState.errors.group_name?.message}
                  sx={{ bgcolor: "#FFFFFF" }}
                  label={t("groupName")}
                  InputLabelProps={{
                    shrink: field.value !== undefined ? true : false,
                  }}
                />
              )}
            />
            {/* full_name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  size="small"
                  {...field}
                  error={!!formState.errors.name}
                  helperText={formState.errors.name?.message}
                  label={t("fullName")}
                  InputLabelProps={{
                    shrink: field.value !== undefined ? true : false,
                  }}
                />
              )}
            />
          </Stack>
          {/* email */}
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                fullWidth
                size="small"
                {...field}
                error={!!formState.errors.email}
                helperText={formState.errors.email?.message}
                label={generalT("email")}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
          {/* phone */}
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                autoFocus
                fullWidth
                size="small"
                {...field}
                error={!!formState.errors.phone}
                helperText={formState.errors.phone?.message}
                label={t("phone")}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />
          <InfoBox text={t("writeTheReason")} variant="warning" />
          {/* note */}
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("reason")}
                fullWidth
                size="small"
                multiline
                rows={5}
                maxRows={5}
                {...field}
                error={!!formState.errors.reason}
                helperText={formState.errors.reason?.message}
              />
            )}
          />
          {/* files */}
          <Box>
            <Typography mb={1}>{t("uploadApproveAttachments")}</Typography>
            <FilePondUploader
              onUpload={(e) => setFiles(e)}
              maxFiles={1}
              maxFileSize={3}
              acceptedFileTypes={[
                "image/*",
                "application/pdf",
                "text/csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
            />
          </Box>

          <Box display={"flex"} justifyContent={"end"} mt={4}>
            <Stack direction={"row"} spacing={1} width={489}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={navigateToRolesManagement}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                loading={isPendingUpdate}
                variant="contained"
                type="submit"
                fullWidth
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};

export default EditCustomer;
