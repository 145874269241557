import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableHeadText from "./table-head-text";
import DownloadIcon from "src/assets/svg-icons/admin/download.svg";
import { downloadFileFromUrl } from "src/utils";

const HistoryTable = ({
  list,
  isLoading,
}: {
  list: any;
  isLoading: boolean;
}) => {
  const { t } = useTranslation("customers");
  const tableHeadText = [
    t("employeeUser"),
    // t("action"),
    t("editFields"),
    t("dateOfAction"),
    t("editReason"),
    t("attachments"),
  ];
  const emptyDataArr = list.length === 0;

  return (
    <Box>
      <Typography fontWeight={600} mb={1}>{t("history")}</Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell align="center" sx={{ bgcolor: "#F9FAFB" }}>
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {list?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.created_by?.name}</TableCell>
                  <TableCell>{row.fields}</TableCell>
                  <TableCell>{row.created_at}</TableCell>
                  <TableCell>{row.fields}</TableCell>
                  <TableCell align="inherit">
                    <Tooltip title={t("attachments")}>
                      {row.file && (
                        <IconButton
                          onClick={() => {
                            downloadFileFromUrl(
                              row.file.url,
                              row.file.name
                            );
                          }}
                        >
                          <img src={DownloadIcon} alt="" />
                        </IconButton>
                      )}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default HistoryTable;
