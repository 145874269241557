import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import folderIcon from "src/assets/svg-icons/side-bar/folder.svg";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";
import { useSendGeneralDataReport } from "src/api/general-data";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import { downloadFile } from "src/utils";

const GeneralData = () => {
  const { t } = useTranslation("general");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { data, refetch, status, isFetching } = useSendGeneralDataReport({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
  });

  const handleSend = () => {
    refetch();
  };

  useEffect(() => {
    if (status === "success" && !isFetching) {
      //   setNotifications([
      //     ...notifications,
      //     {
      //       type: "success",
      //       message: t("sentSuccessfully"),
      //     },
      //   ]);
      const utf8CSVData = `\uFEFF${data}`;
      downloadFile(utf8CSVData, "text/csv", "general-data");
    }
  }, [status, isFetching]);

  return (
    <Stack spacing={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={folderIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("generalData")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} my={3} />
      {/* content */}
      <Stack direction={"row"} gap={2}>
        <Box sx={{ minWidth: 183 }}>
          <DateRangeInput
            startDate={dateRange.startDate ? moment(dateRange.startDate) : null} // moment.Moment | null;
            startDateId={`your_unique_start_date_id`} // moment.Moment | null;
            endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
            endDateId={`your_unique_end_date_id`} // string;
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setDateRange({
                startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                endDate: arg.endDate?.format("yyyy-MM-DD") as string,
              });
            }}
          />
        </Box>
        <LoadingButton
          sx={{
            border: "1px solid #98A2B3",
            height: "40px",
            fontWeight: 500,
            borderRadius: "4px",
            padding: "7px 10px",
            background: "#FCFCFD",
            fontSize: "16px",
          }}
          color="tertiary"
          variant="outlined"
          endIcon={<img src={DownloadIcon} alt="" />}
          loading={isFetching}
          onClick={handleSend}
        >
          {t("send")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
export default GeneralData;
